import React, { createContext, useContext, useState, Dispatch, SetStateAction, FC  } from "react";
import { 
    DataPointSearchFilterType, 
    DataPointSearchFilterContextType,

    AppContextType,
} from '../libs/types';





const initialContext: AppContextType = {
  dataPointSearchFilter: {} as DataPointSearchFilterType,
  setDataPointSearchFilter: (): void => {
    throw new Error('AppContextType(): setDataPointSearchFilter function must be overridden');
  },
  
  sessionId: '',
  setSessionId: (): void => {
    throw new Error('AppContextType(): setSessionId function must be overridden');
  },

  windowId: '',
  setWindowId: (): void => {
    throw new Error('AppContextType(): setWindowId function must be overridden');
  },

  auth0Token: "",
  setAuth0Token: (): void => {
    throw new Error('AppContextType(): setAuth0Token function must be overridden');
  },
  

  // windowMode: 'child',
  // setWindowMode: (): void => {
  //   throw new Error('AppContextType(): setWindowMode function must be overridden');
  // },
};


const AppContext = createContext<AppContextType>(initialContext);


export { AppContext };