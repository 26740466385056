import ReactDOM from 'react-dom/client';
// import { Bugfender } from '@bugfender/sdk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



import { BUILD_NUMBER } from './libs/constants';

// Bugfender.init({
//     appKey: 'A7dILUBvlYKTtrRLBC6OaECth2DqbqRj',
//     // apiURL: 'https://api.bugfender.com',
//     // baseURL: 'https://dashboard.bugfender.com',
//     // overrideConsoleMethods: true,
//     // printToConsole: true,
//     // registerErrorHandler: true,
//     // logBrowserEvents: true,
//     // logUIEvents: true,
//     // version: '',
//     build: BUILD_NUMBER.toString(),
// });


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
