import { useEffect, useState, useContext } from "react";

// import { AppContext } from '../context/AppContext';


const useSessionId = () => {
  // const { dataPointSearchFilter, setDataPointSearchFilter, sessionId, setSessionId } = useContext(AppContext);
  const session_id = localStorage.getItem('session_id')
  // console.debug("useSessionId():ret:", session_id);
  return session_id ? session_id : '';
};

export default useSessionId;