
import * as CryptoJS from 'crypto-js';
import { NavigateFunction } from "react-router-dom";
import { User, useAuth0 } from "@auth0/auth0-react";
import { Logger } from "./react-remote-logger";
import {
  HOTEL_API_ADDR,
} from './constants';

import {
  DataPointType,
  DataPointDetailType,
  SimpleDataPointType,
  HotelSearchFilterType,
  HotelSearchFilterFormType,
  OptionItem,
  BrandOptionItem,
  HotelFilterItem,
  SearchOptionType,
  DataPointSearchBaseDataType,
  DataPointSearchFilterType,
  HotelSearchBaseDataType,
  UserInfoType,
  FilterBaseDataType,
  ContentItemType,
  ContentTableItemType,

  H1ContentArrayType,
  H2ContentArrayType,
  H3ContentArrayType,

  AddressType,

  ShortCodeItemType,

  SavedCardDataType,
  SavedContactDataType,

  BillingInfoFormType,
  ContactFormType,

  RateSearchFormType,
  HttpResponseDataType

} from './types';


/**
 * 
 * @param length : the return string length.
 * @returns a random string,
 */

function GenerateRandomString(length: number):string {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function HttpReq(params: any) {
  let ret = undefined;
  let __session_id = GenerateRandomString(8);
  console.debug(`HttpReq():[${__session_id}]params:`, params)
  // if ('referer' in params) {
  //   delete params.referer;
  // }
  try {
    let savedPath = window.location.pathname;
    let savedSearch = window.location.search;
    console.debug(`HttpReq():[${__session_id}]savedPath:`, savedPath);
    console.debug(`HttpReq():[${__session_id}]savedSearch:`, savedSearch);
    window.history.replaceState(null,'', params?.referer);
    let res = await fetch(HOTEL_API_ADDR, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'include', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        // 'Referer': params?.referer,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrer: params?.referer,
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(params) // body data type must match "Content-Type" header
    });
    // console.debug("HttpReq():res:", res);
    window.history.replaceState(null, '', savedPath + savedSearch);
    if (res.ok) {
      // let res_data = await res.json();
      ret = await res.json();
    } else if (res.status === 503 && !window.location.href.includes('/err503')) {
      // redirect to 503 page
      window.location.href = `${window.location.origin}/err503`;
    }
    
  } catch (error) {
    console.error(error)
  }
  console.debug(`HttpReq():[${__session_id}]ret:`, ret);
  return ret;
}

interface requestAPIParamsType {
  action: string,
  params: object,
  user_token?: string,
  session_id: string,
  referer: string,
}

function requestAPI(func_params: requestAPIParamsType) {
  console.debug("requestAPI():req_params:", func_params);
  if (func_params.session_id.length !== 32 && func_params.action !== 'init_session') {
    console.error("invalid session id, params:", func_params)
    throw new Error("invalid session_id. ");
  } else {
    return HttpReq(func_params);
  }
}

type requestAPIWithTokenParamsType = {
  action: string,
  params: object,
  user_token: string,
  session_id: string,
  referer: string,
}

function requestAPIWithToken(params: requestAPIWithTokenParamsType) {
  console.debug("requestAPIWithToken():req_params:", params);
  let invalid_session_id: boolean = params.session_id.length !== 32 && params.action !== 'init_session';
  let invalid_token: boolean = !params.user_token || params.user_token.length <= 16;
  if (invalid_session_id) {
    console.error("invalid session id, params:", params)
    throw new Error("invalid session_id. ");
  } else if (invalid_token) {
    console.error("invalid user_token, params:", params)
    throw new Error("invalid user_token. ");
  }
  return HttpReq(params);
}

function getGlobalTokenReq(reason: string, referer: string) {
  let req_params: requestAPIParamsType = {
    action: "init_session",
    params: {
      reason: reason
    },
    session_id: '',
    referer: referer
  }
  console.debug("getGlobalTokenReq():req_params:", req_params);
  return requestAPI(req_params);
}


function getUserDetailReq(user_token: string, session_id: string, referer: string) {
  // todo: remove user_uid, 
  let req_params: requestAPIParamsType  = {
    action: 'user:get_detail',
    params: {
      // user_uid: openid
    },
    user_token: user_token,
    session_id: session_id,
    referer: referer
  }
  console.debug("getUserDetail():req_params:", req_params);
  return requestAPI(req_params);
}

function updateUserDetailReq(params: UserInfoType, user_token: string, session_id: string, referer: string) {
  // todo: remove user_uid, 
  let req_params: requestAPIParamsType = {
    action: "user:update",
    params: { 
      // ...{ user_uid: openid }, 
      ...params 
  },
    user_token: user_token,
    session_id: session_id,
    referer: referer
  };
  console.debug("updateUserDetailReq():req_params:", req_params);
  return requestAPI(req_params);
}

function markNotificationAsReadReq(noti_id: number, user_token: string, session_id: string, referer: string) {
  let req_params: requestAPIParamsType = {
    action: "notifications:mark_as_read",
    params: {
      notification_id: noti_id
    },
    user_token: user_token,
    session_id: session_id ? session_id : '',
    referer: referer,
  }
  return requestAPI(req_params);
}

function updateUserSettingReq(params: object, user_token: string, session_id: string, referer: string) {
  let req_params: requestAPIParamsType = {
    action: 'user:update_setting',
    params: params,
    user_token: user_token,
    session_id: session_id,
    referer: referer,
  }

  return requestAPI(req_params);
}

/*
params: {‘pid’: xxx, ‘page’: x, style: stack/tabular} 
*/
function getUserDataPointsReq(params: object, user_token: string, session_id: string, referer: string) {
  let req_params: requestAPIParamsType = {
    // action: "user:get_dp_list",
    action: "user:show_dp",
    params: params,
    user_token: user_token,
    session_id: session_id,
    referer: referer
  }
  return requestAPI(req_params);
}

function getUserNotificationsReq(user_token: string, session_id: string, referer: string ){

}

// =================


function formatDateString(date_string: string | Date) {
  if (date_string) {
    return new Date(date_string).toDateString();
  } else {
    return null;
  }
}


function roomUpgradeInfo(data_point: DataPointType | SimpleDataPointType ) {
  let ret = '未知';
  if (data_point.is_upgraded == null || data_point.is_upgraded === undefined) {

  } else if (data_point.is_upgraded != null && data_point.is_upgraded !== undefined) {
    ret = data_point.is_upgraded ? ` 有 ` : ' 无 ';
    if (data_point.is_upgraded  && data_point.room_booked) {
      //  ${data_point.room_booked.name} -> ${data_point.room_stayed.name}
      ret = ret +  data_point.room_booked.name;
    }

    if (data_point.is_upgraded  && data_point.room_stayed) {
      //  ${data_point.room_booked.name} -> ${data_point.room_stayed.name}
      ret = ret + " 升级到 "  + data_point.room_stayed.name;
    }
  } else {

  }
  // console.debug("roomUpgradeInfo:ret:", ret);
  return ret;
}

function getPaymentType(data_point: DataPointType | SimpleDataPointType | DataPointDetailType) {
  let ret = 'Unknown';
  switch (data_point.payment_type) {
    case 1:
      ret = 'Cash';
      break;
    case 2:
      ret = 'Points';
      break;
    case 3:
      ret = 'Free Night';
      break;
    case 4:
      ret = 'Cash + Points';
      break;


    default:
      ret = 'Unknown';
      break;
  }
  return ret;
}

/**
* 
* @param data_point
* @returns string
* SELF = (1, 'Self')
* BLOG = (2, 'Blog')
* FORUM = (3, 'Forum')
*/

function getSourceType(data_point: DataPointType | SimpleDataPointType | DataPointDetailType) {
  let ret = '';
  switch (data_point.source) {
    case 1:
      ret = '';
      break;
    case 2:
      ret = '(美卡指南)';
      break;
    case 3:
      ret = '(美卡论坛)';
      break;

    default:
      ret = '';
      break;
  }
  return ret;
}


const convertToFormValue = function (values: HotelSearchFilterType): HotelSearchFilterFormType {
  console.debug("convertToFormValue():values:", values);
  let ret: HotelSearchFilterFormType = {
    chains: [],
    brands: [],
    from: values?.from_page ? values?.from_page : ''
  }

  let chain_options: OptionItem[] = [];
  let brand_options: BrandOptionItem[] = [];

  console.debug("convertToFormValue():chain_options:", chain_options);

  ret.chains = chain_options;
  ret.brands = brand_options;

  let hotels_locations: SearchOptionType[] = [];
  if (values.hotels) {
    values.hotels.forEach((item) => {
      hotels_locations.push(item)
    })
  }

  if (values.locations) {
    values.locations.forEach((item) => {
      hotels_locations.push(item);
    })
  }

  ret.hotels_locations = hotels_locations;

  // console.debug("convertToFormValue():ret:", ret);


  return ret;
}

const convertToContextData = function (values: HotelSearchFilterFormType): HotelSearchFilterType {
  console.debug("convertToContextData(): values:", values);
  // let hotel_filter: HotelFilterItem[] = [];
  let ret: HotelSearchFilterType = {

    // hotel_filter: hotel_filter,
  }

  if (Array.isArray(values.hotels_locations) && values?.hotels_locations.length >= 0) {
    let hotels: SearchOptionType[] = [];
    let locations: SearchOptionType[] = [];

    values.hotels_locations?.forEach((item) => {
      console.debug("convertToContextData(): hotel_location item:", item);
      if (typeof item === 'object' && 'hotel' in item) {
        hotels.push(item);
      } else if (typeof item === 'object' && 'location' in item) {
        locations.push(item);
      }
    })

    ret.hotels = hotels;
    ret.locations = locations;
  } else if (!values.hotels_locations && (Array.isArray(values.hotels) || Array.isArray(values.locations))) {
    ret.hotels = values?.hotels ? values?.hotels : [];
    ret.locations = values?.locations ? values?.locations : [];
  }

  if (values.chains) {
    ret.chains = values.chains;
  }

  if (values.brands) {
    ret.brands = values.brands;
  }


  if (values.offer &&  Object.keys(values.offer).length>0) {
    ret.offers = [values.offer];
  }

  if (values.min_pts) {
    ret.min_pts = values.min_pts;
  }

  if (values.max_pts) {
    ret.max_pts = values.max_pts;
  }

  if (values.from) {
    ret.from_page = values.from;
  }

  if (values.programs) {
    ret.programs = values.programs;
  }

  console.debug("convertToContextData():ret:", ret);
  return ret;

}

const convertToHotelSearchParams = convertToContextData;

function getWindowSize() {
  // const { innerWidth, innerHeight } = window;
  // console.debug("getWindowSize(): innerWidth:", innerWidth, "innerHeight:", innerHeight )

  const innerWidth = document.documentElement.clientWidth;
  const innerHeight = document.documentElement.clientHeight;
  console.debug("getWindowSize(): innerWidth:", innerWidth, "documentHeight:", innerHeight )

  return { innerWidth, innerHeight };
}

function __getDeviceType<Type extends { innerWidth: number, innerHeight: number }>(params: Type): string {
  let ret = "unknown";
 
  if (params.innerWidth <= 900 ) {
    ret = 'mobileOrTablet'
  } else if (params.innerWidth > 900) {
    ret = 'desktopOrLaptop'
  }
  return ret
}

function getDeviceType() {
  return __getDeviceType(getWindowSize());
}

function getCreatedDays(date: Date | string): number {
  let ret = -1;
  let now_timestamp = new Date().getTime();
  try {
    let date_timestamp = new Date(date).getTime()
    let gap = now_timestamp - date_timestamp;

    ret = gap / 1000 / 86400;
  } catch (error) {
    console.error(error)
  }

  // console.debug(date_timestamp);
  // console.debug("getCreatedDays():ret:", ret)
  return ret;
}

function reloadPage() {
  window.location.reload();
}


// const md5 = (contents: string) => crypto.createHash('md5').update(contents).digest("hex");
const md5 = (contents: string | undefined): string => {
  if (contents) {
    return CryptoJS.MD5(contents).toString();
    // return md5(contents).toString();
  }

  return '';
}

const getHashedUserId = (user_id: string): string => {
  let salt = 'menshengfadacai';
  return md5(salt + user_id);
}

function end_session(reason: string) {
  let session_id = localStorage.getItem('session_id');
  console.debug("end_session(): session_id:", session_id);

  // do nothing
  // if (session_id) {
  //   console.debug("end_session(): need clean session_id")
  //   localStorage.removeItem('session_id');
  //   requestAPI({
  //     action: 'end_session',
  //     session_id: session_id,
  //     params: {
  //       reason: reason
  //     },
  //     referer: window.location.href,
  //   })
  // }
}

const getGlobalSessionId = async (reason: string): Promise<String> => {
  let session_id = getSessionId();
  let ret = undefined;
  if (session_id.length === 0) {
    let res = await getGlobalTokenReq(reason, window.location.href);
    if (res && res?.code === 0 && res.session_id) {
      localStorage.setItem('session_id', res.session_id);
      ret = res.session_id;
    }
  }
  return ret;
}

const getWindowCount = (): number => {
  let ret = -1;
  try {
    let tmp = localStorage.getItem('wCount');
    console.debug("getWindowCount(): tmp:", tmp)
    if (tmp != null) {
      console.debug("getWindowCount(): valid count:", tmp)
      ret = parseInt(tmp)
      console.debug("getWindowCount():mark 1 ret:", ret)
    }

  } catch (error) {
    console.error(error);
  }
  console.debug("getWindowCount():ret:", ret);
  return ret;
}

const setWindowCount = (n: number) => {
  localStorage.setItem('wCount', n.toString());
}

const getMainWindowId = (): string | null => {
  let ret = null;
  try {
    ret = localStorage.getItem('mWid');
  } catch (error) {
    console.error(error);
  }
  // console.debug("getMainWindowId():ret:", ret)
  return ret;
}

const updateMainWindowId = (id: string): void => {
  localStorage.setItem('mWid', id);
}

const deleteMainWindowId = () => {
  localStorage.removeItem('mWid');
}

const getSessionId = () => {
  const session_id = localStorage.getItem('session_id')
  // console.debug("useSessionId():ret:", session_id);
  return session_id ? session_id : '';
}

const myLogger = (line: string) => {
  Logger({
    'timestamp': (new Date()).toLocaleString(),
    'level': 'info',
    'message': line
  });
}

const redirectToLink = (navigate: NavigateFunction, user_token: string, session_id: string) => {
  let redirect_url = localStorage.getItem('redirect_url');
  console.debug("redirectToLink():redirect_url:", redirect_url);
  if (redirect_url) {
    localStorage.removeItem('redirect_url');
    // upload user login action  to backend,
    navigate(redirect_url);
    let req_params = {
      action: "user:login",
      params: {
      },
      session_id: session_id,
      referer: window.location.href,
      user_token: user_token,
    }
    requestAPI(req_params)
    // console.debug("redirectToLink():req_params:", req_params);
  }
}

const getOptionDataForQueryString = (query_string: string | null, option_data: DataPointSearchBaseDataType, query_key: keyof DataPointSearchBaseDataType) => {
  console.debug("getOptionDataForQueryString():query_string:", query_string);
  console.debug("getOptionDataForQueryString(): option_data:", option_data);
  let found_list: OptionItem[] = [];
  if (query_string != null) {

    try {
      let query_list = query_string?.split("|");
      console.debug("getOptionDataForQueryString():query_list:", query_list);
      console.debug("getOptionDataForQueryString():data_list.length:", query_list.length);
      if (Array.isArray(query_list) && query_list.length > 0) {
        query_list.forEach(item => {
          let found_item = option_data[query_key].find((group_item: OptionItem) => {
            return group_item.label.toLocaleLowerCase() === item.toLocaleLowerCase();
          });
          if (found_item) {
            found_list.push(found_item);
          }
        });

        console.debug("getOptionDataForQueryString():found_list:", found_list);
      }
    } catch (error) {
      console.error(error)
    }
  }

  return found_list;
}

const convertSearchQueryToDataPointSearchParams = (params: URLSearchParams, base_data: DataPointSearchBaseDataType): DataPointSearchFilterType => {
  console.debug("convertSearchQueryToDataPointSearchParams(): params:", params);
  console.debug("convertSearchQueryToDataPointSearchParams(): hotels:", params.get('hotels'));


  // let chain = params.get('chain')
  // console.debug("convertSearchQueryToDataPointSearchParams(): chain:", chain);
  let valid_keys = Object.keys(base_data);

  valid_keys.push('locations');
  valid_keys.push('hotels');
  console.debug("convertSearchQueryToDataPointSearchParams():valid_keys:", valid_keys);
  let req_params: any = {}

  // ?groups=Marriott,hyatt&brands=Courtyard,MOXY%20Hotels&members=Marriott%20Member,Hyatt%20Member&programs=IHG%20Luxury
  // http://localhost:3000/?hotels=Caresse,%20a%20Luxury%20Collection%20Resort%20&%20Spa,%20Bodrum%7CThe%20Ritz-Carlton,%20Chicago
  // http://localhost:3000/?hotels=Caresse,%20a%20Luxury%20Collection%20Resort%20%26%20Spa,%20Bodrum%7CThe%20Ritz-Carlton,%20Chicago

  // & -> %26
  // | -> %7C
  valid_keys.forEach((key_name, idx) => {
    let query_string = params.get(key_name);
    console.debug("convertSearchQueryToDataPointSearchParams(): query_string:", query_string);
    if (query_string) {
      if (key_name === 'reviews') {
        req_params['review_items'] = getOptionDataForQueryString(query_string, base_data, key_name);
      } else if (key_name === 'locations') {
        let location_list = query_string.split("|")
        let found_list: string[] = [];
        location_list.forEach((location, idx) => {
          console.debug("location placeid:", location);
          found_list.push(location)
        });
        if (found_list.length > 0) {
          req_params['locations'] = found_list;
        }

      } else if (key_name === 'hotels') {
        let hotel_list = query_string.split("|")
        console.debug("convertSearchQueryToDataPointSearchParams():hotel_list:", hotel_list)
        let found_list: string[] = [];
        hotel_list.forEach((hotel, idx) => {
          console.debug("hotel uid:", hotel);
          found_list.push(hotel)
        });
        if (found_list.length > 0) {
          req_params['hotels'] = found_list;
        }
      }
      else {
        req_params[key_name] = getOptionDataForQueryString(query_string, base_data, key_name as keyof DataPointSearchBaseDataType);
      }
    }
  })

  if (Object.keys(req_params).length > 0) {
    req_params['from'] = 'url_query';
  }

  console.debug("convertSearchQueryToDataPointSearchParams(): ret:", req_params);
  return req_params;
}


const getOptionDataForHotelQueryString = (query_string: string | null, option_data: HotelSearchBaseDataType, query_key: keyof HotelSearchBaseDataType) => {
  console.debug("getOptionDataForHotelQueryString():query_string:", query_string);
  console.debug("getOptionDataForHotelQueryString(): option_data:", option_data);
  let found_list: OptionItem[] = [];
  if (query_string != null) {

    try {

      let query_list = query_string?.split("|");

      console.debug("getOptionDataForHotelQueryString():query_list:", query_list);
      console.debug("getOptionDataForHotelQueryString():data_list.length:", query_list.length);
      if (Array.isArray(query_list) && query_list.length > 0) {
        query_list.forEach(item => {
          let found_item = option_data[query_key].find((group_item: OptionItem) => {
            return group_item.label.toLocaleLowerCase() === item.toLocaleLowerCase();
          });
          if (found_item) {
            found_list.push(found_item);
          }

        });

        console.debug("getOptionDataForHotelQueryString():found_list:", found_list);
      }
    } catch (error) {
      console.error(error)
    }
  }

  return found_list;
}

const convertSearchQueryToHotelSearchParams = (params: URLSearchParams, base_data: HotelSearchBaseDataType): HotelSearchFilterFormType => {
  console.debug("convertSearchQueryToHotelSearchParams(): params:", params);
  console.debug("convertSearchQueryToHotelSearchParams(): base_data:", base_data);


  let valid_keys = Object.keys(base_data);
  console.debug("convertSearchQueryToHotelSearchParams():valid_keys:", valid_keys);
  valid_keys.push('offer')
  valid_keys.push('min_pts')
  valid_keys.push('max_pts')
  valid_keys.push('hotels')
  valid_keys.push('locations')
  let req_params: any = {
  }


  valid_keys.forEach((key_name, idx) => {
    let query_string = params.get(key_name);
    console.debug("convertSearchQueryToHotelSearchParams(): query_string:", query_string);
    if (query_string) {
      console.debug("convertSearchQueryToHotelSearchParams():key_name:", key_name);

      if (key_name === 'locations' || key_name === 'hotels') {
        let data_list = query_string.split("|")
        let found_list: string[] = [];
        data_list.forEach((item, idx) => {
          console.debug("location/hotel:", item);
          found_list.push(item)
        });
        if (found_list.length > 0) {
          req_params[key_name] = found_list;
        }
      } else if (key_name === 'max_pts' || key_name === 'min_pts') {
        try {
          req_params[key_name] = parseInt(query_string);
        } catch (error) {
          console.error(error)
        }
      }

      else if (key_name === 'offer') {
        let found = base_data.offers.find((item, idx) => {
          if (query_string != null) {
            return parseInt(item.value) === parseInt(query_string)
          } else {
            return null;
          }
        });

        console.debug("convertSearchQueryToHotelSearchParams():offer found:", found);
        req_params[key_name] = found;
      }

      else {
        req_params[key_name] = getOptionDataForHotelQueryString(query_string, base_data, key_name as keyof HotelSearchBaseDataType);
      }
    }
  })

  if (Object.keys(req_params).length > 0) {
    req_params['from'] = 'url_query';
  }

  console.debug("convertSearchQueryToHotelSearchParams(): ret:", req_params);
  return req_params;
}

// const base64toBlob = (b64Data:string, contentType='', sliceSize=512) => {
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, {type: contentType});
//   return blob;
// }

function Base64toBlob(base64Data: string, contentType: string): Blob {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

function getRandomNumberBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const fetchSearchBaseData = async (session_id: string, page?: string, callback?: (data: FilterBaseDataType) => void) => {
  try {
    let params = {
      action: "searchbox:get_base",
      params: {
        from: page ? page : 'unknown'
      },
      session_id: session_id,
      referer: window.location.href,
    }

    requestAPI(params).then((res) => {
      if (res.code === 0) {
        console.debug("fetchSearchBaseData():res.data:", res.data);
        callback && callback(res.data)
      }
    })

  } catch (error) {
    console.error("error", error);
  }
};

function convertHtmlStringToPlainText(html:string){

  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element 
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

function decodeHtml(html: string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

function scrollToAnchor(anchor_name:string) {
  let ele = document.getElementById(anchor_name);
  if (ele) {
    ele.scrollIntoView({ behavior: 'smooth' });
  }
}

  /**
   * 
   * 就以下几种情况：
    预定和升级房型都有：
       如果字段is_upgraded是true，外面和现在一样显示“有”，移上去显示<入住>→<升级>
       如果字段is_upgraded是false，外面和现在一样显示“没有”，移上去就只显示<入住>
    只有预定/升级或都没有，is_upgraded肯定是null，就和现在一样显示未知。如果有房型，移上去显示那个仅有的。
   */

const getRoomUpgradeInfo = (data_point: SimpleDataPointType):  ContentItemType[] => {
  console.debug("getRoomUpgradeInfo(): data_point:", data_point);  
  let ret:ContentItemType = 
    {
      content: "",
      tooltip: ""
    }

  if (data_point.is_upgraded === true) {
    ret.content  = '有'
    let booked_room = data_point.room_booked?.name ? `预定: ${data_point.room_booked?.name}` : '';
    let stayed_room = data_point.room_stayed?.name ? `入住: ${data_point.room_stayed?.name}` : '';
    ret.tooltip = `${booked_room} <br/> ${stayed_room}`;
    
  } else if (data_point.is_upgraded === false) {
    ret.content = '无'
    let stayed_room = data_point.room_stayed?.name ? `入住: ${data_point.room_stayed?.name}` : '';
    ret.tooltip = `${stayed_room}`;

  } else {
    ret.content = '未知'
    let booked_room = data_point.room_booked?.name ? `预定: ${data_point.room_booked?.name}` : undefined;
    let stayed_room = data_point.room_stayed?.name ? `入住: ${data_point.room_stayed?.name}` : undefined;
    if (booked_room) {
      ret.tooltip = `${booked_room}`;
    }
    if (stayed_room) {
      ret.tooltip = ret.tooltip + `<br/> ${stayed_room}`;
    }
  }


  if (ret.tooltip && ret.tooltip.trim() === '<br/>') {
    ret.tooltip = "";
  }


  console.debug("getRoomUpgradeInfo():ret:", ret);
  return [ret]

}


// datapoint detail 页面
function GetContentTableFromString(text: string, source?:number): ContentTableItemType[] {
  // let ret: ContentTableItemType[] = []

  let el = document.createElement('html');
  el.innerHTML = text;

  // let h2_list = el.getElementsByTagName('h2');
  // console.debug('GetContentTableFromString(): h2_list:', h2_list);

  
  // console.debug('GetContentTableFromString(): mutil_tag_list:', mutil_tag_list);
  // mutil_tag_list.forEach((item, idx) => {
  //   console.debug(item);
  // });

  let content_table_data: ContentTableItemType[] = [];

  
  

  // from blog or forum
  if (source === 2 || source === 3) {
    let mutil_tag_list = el.querySelectorAll('h1, h2, h3');
    Array.from(mutil_tag_list).forEach((tag,index)=>{
      console.debug("GetContentTableFromString(): tag:", tag)
      console.debug("GetContentTableFromString(): tag:", tag.textContent);
      let span_ele = tag.getElementsByTagName('span').length > 0 ? tag.getElementsByTagName('span')[0] : null;
      if (span_ele) {
        // content from blog
        // console.debug('GetContentTableFromString(): content item:', tag);
        // console.debug('GetContentTableFromString(): tagName:', tag.tagName);
        // console.debug('GetContentTableFromString(): is h3 ?', tag.tagName === 'H3');

        let title = span_ele.innerText.replace(/^\s+|\s+$/g, '');
        // if (tag.tagName === 'H3') {
        //   title = "    " + title;
        // }
        // console.debug('GetContentTableFromString(): content title:', title);
        content_table_data.push({
          title: title,
          anchor: `#${span_ele['id']}`,
          tag_name: tag.tagName 
        });
      }    
    })
  } 
  else if (source === 1) {
    let element_array = Array.from(el.getElementsByTagName("*"));

    element_array.forEach((ele, idx) => {
      console.debug("GetContentTableFromString(): ele:", ele);
      console.debug("GetContentTableFromString(): ele.tagname:", ele.tagName);
      console.debug("GetContentTableFromString(): ele.id:", ele.id);
      if (ele.tagName === 'H1'|| ele.tagName === 'H2' || ele.tagName === 'H3') {
        let title = ele.textContent ? ele.textContent.trim() : null;
        if (title ) {
          content_table_data.push({
            title: title,
            anchor: `#${idx}`,
            tag_name: ele.tagName 
          });
        }  
      }
    })
  }

  console.debug('GetContentTableFromString():content_table_data:', content_table_data);
  
  return content_table_data;
}


// 切割datapoint content
function GetContentListFromString(text: string, source?: number):  HTMLElement[] {
  console.debug("GetContentListFromString(): source:", source);
  let el = document.createElement('html');
  el.innerHTML = text;
  let body_ele = el.getElementsByTagName('body')[0];
  // if (body_ele) {
  //   let children = body_ele.children;
  //   for (var i = 0; i < children.length; i++) {
  //     let child  = children[i];
  //     console.debug("GetContentListFromString(): child:", child.innerHTML);
  //   }
    
  // } else {
    
  // }
  let ret = [].slice.call(Array.from(body_ele.children));

  // from blog for forum
  if (source === 1 ) {
    ret.forEach((tag: HTMLElement, index:number) =>{
      console.debug("GetContentListFromString(): tag:", tag.tagName);
      if (tag.tagName === 'H1' || tag.tagName === 'H2' || tag.tagName === 'H3') {
        console.debug("GetContentListFromString(): add id:", index, " to tag:", tag);
        tag.setAttribute("id", index.toString());
      }
      
      // console.debug("GetContentListFromString(): tag:", tag.tagName);
      // let isFromBlog = tag.querySelector("span");
      // 
    
    })
  
  }
    
  return ret;

  // return body_ele.children ?  Array.from(body_ele.children) : [];
}

function GetContentListFromStringArray(string_array: string[], source?: number): Element[] {
  console.debug("GetContentListFromStringArray(): source:", source);
  console.debug("GetContentListFromStringArray(): string_array:", string_array);
  return GetContentListFromString(string_array.join(''), source);
}

function ConvertToPlainArray(string_array: H1ContentArrayType|H2ContentArrayType|H3ContentArrayType): string[] {
  let ret: string[] = [];
  
  string_array.forEach((h1_item) => {
    if (Array.isArray(h1_item)) {
      h1_item.forEach((h2_item) => {
        if (Array.isArray(h2_item)) {
          h2_item.forEach((h3_item) => {
            ret.push(h3_item)
          })
        }
        else {
          ret.push(h2_item)
        }
      })
    } else {
      ret.push(h1_item)
    }
  })
  console.debug("ConvertToPlainArray(): ret:", ret);
  return ret;
}

function GetContentTableFromStringArray(string_array: H1ContentArrayType|H2ContentArrayType|H3ContentArrayType, source?: number):  ContentTableItemType[] {
  return  GetContentTableFromString(ConvertToPlainArray(string_array).join(''), source);
}

function GetContentTableFromElementArray(ele_array: Element[]):ContentTableItemType[]  {
  let ret:ContentTableItemType[] =  [];

  ele_array.forEach((item,idx)=>{
    console.debug("GetContentTableFromElementArray(): item:", item);
    if (item.tagName === 'H1'|| item.tagName === 'H2' || item.tagName === 'H3') {
      ret.push({
        title: item.textContent? item.textContent : `目录${idx}`,
        anchor: item.id,
        tag_name: item.tagName,
      })

    }
  })
  console.debug("GetContentTableFromElementArray():ret:", ret);
  return ret;
}

function getDateFromString  (data: string | boolean | AddressType | undefined): Date {
  let ret = new Date();
  if (typeof data === 'string') {
    ret = new Date(data);
  }
  return ret;
};

function getNodeHTML(node: Node): string {
  let html = '';

  if (node.nodeType === Node.TEXT_NODE) {
    html += node.textContent || '';
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as Element;
    html += `<${element.tagName.toLowerCase()}>`;
    for (let i = 0; i < element.childNodes.length; i++) {
      html += getNodeHTML(element.childNodes[i]);
    }
    html += `</${element.tagName.toLowerCase()}>`;
  }

  return html;
}




function splitStringWithBrackets(inputString: string): string[] {
  const splitStrings = inputString.split(/\[|\]/);

  const resultArray: string[] = [];
  for (let i = 0; i < splitStrings.length; i++) {
    if (i % 2 !== 0) {
      resultArray.push(`[${splitStrings[i]}]`);
    } else if (splitStrings[i] !== '') {
      resultArray.push(splitStrings[i]);
    }
  }

  return resultArray;
}

function parseBracketString(inputString: string): ShortCodeItemType|undefined {
  // const regex = /\[([^\s]+) id='([^']+)' display='([^']+)'\]/;
  // const regex = /\[([^\s]+) id='([^']+)'\]/;
  // const regex = /\[([^\s]+)\s+id="([^"]+)"(?:\s+display="([^"]+)")?\]/
  const regex = /\[([^\s]+)\s+id=(?:"([^"]+)"|'([^']+)')(?:\s+display=(?:"([^"]+)"|'([^']+)'|))?\]/;
  const matches = inputString.match(regex);

  if (matches) {
    // const [, name, id, display] = matches;
    const [, shortcode, idDoubleQuotes, idSingleQuotes, displayDoubleQuotes, displaySingleQuotes] = matches;

    const id = idDoubleQuotes || idSingleQuotes;
    const display = displayDoubleQuotes || displaySingleQuotes || '';
    return { name: shortcode, id: id, display: display };
  } 
  return undefined;
}

function completeHtmlTags(arr: string[]): string[] {
  let prevTag: string | null = null;

  let ret: string[];
  ret = arr.map((item) => {
    if (item.startsWith('[')) {
      return item;
    } else {
      const startsWithTag = /^<\w+>/.test(item);
      const endsWithTag = /<\/\w+>$/.test(item);

      if (startsWithTag && !endsWithTag) {
        const tagName = item.match(/^<(\w+)>/)![1];
        prevTag = tagName;
        return item + `</${tagName}>`;
      } else if (!startsWithTag && endsWithTag) {
        const tagName = prevTag!;
        return `<${tagName}>` + item;
      } else if (!startsWithTag && !endsWithTag) {
        if (prevTag) {
          const tagName = prevTag;
          return `<${tagName}>` + item + `</${tagName}>`;
        } else {
          return item; // 如果前面没有任何tag，则不做处理
        }
      }

      prevTag = null;
      return item;
    }
  });

  return ret;
}

function complestShortCode(arr: string[]): (string|ShortCodeItemType|undefined)[] {
  let ret: (string|ShortCodeItemType|undefined)[] = [];

  arr.forEach((item) => {
    if (item.startsWith('[')){
      ret.push(parseBracketString(item));
    } else {
      ret.push(item)
    }
  })
  return ret;
}

function complectShortCodeItem(line: string): (string|ShortCodeItemType|undefined) {
  return line.startsWith('[') ? parseBracketString(line) : line
}


// function splitStringByHTMLTags(inputString: string): string[] {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(inputString, 'text/html');

//   const nodes = Array.from(doc.body.childNodes);
//   const resultArray: string[] = [];

//   nodes.forEach(node => {
//     console.debug("splitStringByHTMLTags(): node:", node)
//     const html = getNodeHTML(node);
//     if (html.trim().length > 0) {
//       resultArray.push(html);
//     }
//   });

//   return resultArray;
// }


function splitStringByHTMLTags(inputString: string): string[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputString, 'text/html');

  const nodes = Array.from(doc.body.childNodes);
  const resultArray: string[] = [];

  nodes.forEach(node => {
    const serializer = new XMLSerializer();
    const html = serializer.serializeToString(node)
      .replace(/ xmlns="http:\/\/www\.w3\.org\/1999\/xhtml"/g, '');

    if (html.trim().length > 0) {
      resultArray.push(html);
    }
  });

  return resultArray;
}


function parseContent(content: string):(string|ShortCodeItemType|undefined)[][] {
  let step_1_result = splitStringByHTMLTags(content);
  console.debug("parseContent():splitStringByHTMLTags(): ret:", step_1_result);
  let ret: (string|ShortCodeItemType|undefined)[][]= [];
  step_1_result.forEach((line,idx)=>{
    let line_item = splitStringWithBrackets(line);
    console.debug("parseContent():splitStringWithBrackets(): line_item:", line_item);
    let complete_line_item = completeHtmlTags(line_item);
    console.debug("parseContent():splitStringWithBrackets(): complete_line_item:", complete_line_item);
    let line_list: (string|ShortCodeItemType|undefined)[] = [];
    complete_line_item.forEach((item,idx)=>{
      // console.debug("item:", item);
      line_list.push(complectShortCodeItem(item))
    })
    console.debug("parseContent(): line_list:", line_list);
    ret.push(line_list);
    
    
  })
  console.debug("parseContent(): ret:", ret);
  return ret;
}


function convertToBillingFormData(data: SavedCardDataType | undefined): BillingInfoFormType {
  console.debug('convertToBillingFormData(): data:', data);
  let ret: BillingInfoFormType = {
    uid: data?.uid || '',
    first_name:  data?.first_name || '',
    last_name:   data?.last_name ||  '',
    email:  data?.email || '',
    phone:  data?.phone || '',
    card_number: data?.card_number || '',
    card_nickname: data?.card_nickname || '',
    expiration_month: data?.expiration_month  || undefined,
    expiration_year:  data?.expiration_year || undefined,
    is_default_card: data?.is_default_card || false,
    set_as_default: false,
    save_to_profile:  false,
    security_code: data?.security_code || '',
    address: {
      country_or_region: data?.country_or_region  || '',
      street_line1: data?.street_line1  || '',
      street_line2: data?.street_line2  ||'',
      city_or_locality: data?.city_or_locality || '',
      state_or_province: data?.state_or_province || '',
      zipcode: data?.zipcode || '',
    },
  };


  console.debug('convertToBillingFormData():ret:', ret);
  return ret;
}

function convertToContactFormData(data: SavedContactDataType): ContactFormType {
  let ret: ContactFormType = {
    first_name:  data?.first_name || '',
    last_name:   data?.last_name ||  '',
    email:  data?.email || '',
    phone:  data?.phone || '',
    loyalty_number: data?.loyalty_number || '',
    save_to_profile: false,
    uid: data.uid,

    address: {
      country_or_region: data?.country_or_region  || '',
      street_line1: data?.street_line1  || '',
      street_line2: data?.street_line2  ||'',
      city_or_locality: data?.city_or_locality || '',
      state_or_province: data?.state_or_province || '',
      zipcode: data?.zipcode || '',
    },
  };

  return ret;
}

// function convertToRateSearchFormData(data:UserReservationFormType ) : RateSearchFormType {
//   let ret: RateSearchFormType = {
//     check_in: data.check_in,
//     check_out: data.check_out,
//     hotels?: SimpleHotelInfoType[];
//     locations?: LocationInfoType[];
//     nights: number;
//     number_of_adults: number;
//     number_of_children: number;
//     children: number[];
//     platforms?: PlatformItemType[];
//     programs?: ProgramItemType[];
//     req_info_alt?: object;
//     room_preference?: number;
//   }
//   return ret;
// }

function cleanHtmlTag(line: string):(string) {
  if (typeof(line)=== 'string') {
    return line.replace(/<[^>]*>/g, "");
  } else {
    return ''
  }
}

function getDate(date_string: string|undefined): Date {
  let ret = new Date();
  try {
    if (date_string) {
      let split_str =  date_string.includes('-') ? '-' : date_string.includes('/') ? '/' : undefined;
      if (split_str) {
        let data_list = date_string.split(split_str);
        ret = new Date(parseInt(data_list[0]), parseInt(data_list[1]) - 1, parseInt(data_list[2]), 0,0,0,0);    
      } else {
        throw Error('invalid date string. no / or - in the line.')
      }
    } else {
      throw Error('date cannot undefined.')
    }

  } catch (error) {
    console.debug(error)
  }
  return ret;
}


function capitalizeFirstLetter(input: string): string {
  if (!input) return input;
  
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}


export {
  HttpReq,
  requestAPI,
  GenerateRandomString,


  formatDateString,
  roomUpgradeInfo,
  getPaymentType,
  getSourceType,

  convertToContextData,
  convertToFormValue,

  convertToHotelSearchParams,

  getWindowSize,
  getDeviceType,

  getCreatedDays,

  reloadPage,

  md5,
  getHashedUserId,

  redirectToLink,

  // ====
  getGlobalTokenReq,
  getUserDetailReq,
  updateUserDetailReq,
  markNotificationAsReadReq,
  updateUserSettingReq,
  getUserDataPointsReq,
  end_session,

  getWindowCount,
  setWindowCount,

  getMainWindowId,
  updateMainWindowId,
  deleteMainWindowId,

  getSessionId,

  myLogger,

  convertSearchQueryToDataPointSearchParams,
  convertSearchQueryToHotelSearchParams,

  Base64toBlob,
  getRandomNumberBetween,

  fetchSearchBaseData,

  convertHtmlStringToPlainText,
  decodeHtml,

  scrollToAnchor,
  getRoomUpgradeInfo,

  GetContentTableFromString,
  GetContentTableFromStringArray,
  
  GetContentListFromString,
  GetContentListFromStringArray,
  GetContentTableFromElementArray,

  ConvertToPlainArray,
  getDateFromString,

  parseContent,

  convertToBillingFormData,
  convertToContactFormData,

  cleanHtmlTag,

  getDate,

  capitalizeFirstLetter,
}